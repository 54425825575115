import { makeStyles, Card } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: "2px solid rgba(13, 243, 196, 0.25)",
    borderRadius: 25,

    display: "flex",

    flexDirection: "column",
    boxShadow: "none",

    height: ({ height }) => (height ? height : 430),
    // marginRight: 16,
    padding: 24,
    marginBottom: 16,

    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 0,
      // height: 500,
    },
  },
}));

const CustomCard = ({ children, height, width, style, customStyles }) => {
  const classes = useStyles({ height, width });

  return (
    <Card elevation={1} className={[classes.card, customStyles]} style={style}>
      {children}
    </Card>
  );
};

export default CustomCard;
