import {
  Snackbar as SnackbarMui,
  IconButton,
  Slide,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ClearOutlined, LinkOutlined } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "../hooks/uiHooks";

const Snackbar = () => {
  const { open, message, severity, transactionHash, hideSnackbarF } =
    useSnackbar();

  const classes = useStyles();

  const TransitionUp = (props) => {
    return <Slide {...props} direction="down" />;
  };

  return (
    <SnackbarMui
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{ anchorOriginTopCenter: classes.root }}
      open={open}
      autoHideDuration={6000}
      onClose={(e, reason) => {
        console.log("reason => ", reason);
        if (reason === "clickaway") {
          return;
        }
        hideSnackbarF();
      }}
      TransitionComponent={TransitionUp}
      // action={
      //   <IconButton
      //     size="small"
      //     aria-label="close"
      //     color="inherit"
      //     onClick={hideSnackbarF}
      //     style={{ color: "#fff" }}
      //   >
      //     <ClearOutlined fontSize="small" style={{ color: "#fff" }} />
      //   </IconButton>
      // }
    >
      <Alert
        onClose={hideSnackbarF}
        severity={severity}
        className={classes.snackbarStyles}
        // style={open ?  : { display: "none" }}
        classes={{ action: classes.iconColor }}
      >
        <Typography className={classes.text}>{message}</Typography>
      </Alert>
    </SnackbarMui>
  );
};

export default Snackbar;

const useStyles = makeStyles((theme) => ({
  root: {},

  snackbarStyles: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
  },

  text: {
    color: theme.palette.textColor,
  },

  iconColor: {
    color: "#fff",
  },
}));
