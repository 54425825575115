import { makeStyles, Box, Typography, Button, Link } from "@material-ui/core";
import React from "react";
import { UniswapSvg, PancakeSwapSvg, AscendSvg, GateIOSVG } from "../../assets";
import HolderCard from "./HolderCard";
import PieChart from "./PieChart";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 95,
    // minWidth: 730,
    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",
    border: "2px solid rgba(13, 243, 196, 0.25)",
    borderRadius: "25px",

    paddingBottom: 0,
    marginBottom: 16,
    display: "flex",
    alignItems: "center",
    padding: "0 24px",

    [theme.breakpoints.down("1100")]: {
      padding: 16,
      alignItems: "center",
    },

    [theme.breakpoints.down("650")]: {
      padding: 16,

      alignItems: "flex-start",
      marginRight: 0,
      flexDirection: "column",
      minHeight: "auto",
    },
  },

  heading: {
    fontSize: "24px",
    lineHeight: "19px",
    color: "#fff",
    fontWeight: 500,
    marginRight: 30,

    [theme.breakpoints.down("650")]: {
      marginBottom: 24,
      marginRight: 0,
    },
  },

  exchangeBtn: {
    marginRight: 15,

    borderRadius: 10,
    padding: 0,

    [theme.breakpoints.down("650")]: {
      marginBottom: 16,
      width: "90%",
      marginRight: 0,
    },
  },

  exchangeBtnText: {
    fontSize: "14px",
    lineHeight: "10px",
    color: "#090613",
    fontWeight: 400,
  },

  exchangeBtnContainer: {
    display: "flex",
    flex: 1,

    [theme.breakpoints.down("650")]: {
      flexDirection: "column",

      width: "100%",
      alignItems: "center",
    },
  },

  linkBtn: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    minHeight: 45,
    marginRight: 15,

    borderRadius: 10,
    padding: 0,

    color: "rgba(0, 0, 0, 0.87)",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    backgroundColor: "#e0e0e0",

    [theme.breakpoints.down("650")]: {
      marginBottom: 16,
      width: "90%",
      marginRight: 0,
    },
    "&:hover": {
      textDecoration: "none",
    },
  },

  disabledButton: {
    backgroundColor: "#7d7d7d !important",
  },
}));

const ExchangesCard = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.heading}>Exchanges</Typography>

      <Box className={classes.exchangeBtnContainer}>
        <Link
          href="https://v2.info.uniswap.org/pair/0xcd4a2f72e3d646e8addab74a68c2175d6a36b0e3"
          target="_blank"
          className={classes.linkBtn}
        >
          <img src={UniswapSvg} style={{ marginRight: 5, marginBottom: 4 }} />
          <Typography className={classes.exchangeBtnText}>Uniswap</Typography>
        </Link>

        <Link
          href="https://m.ascendex.com/en/cashtrade-spottrading/usdt/dafi"
          target="_blank"
          className={classes.linkBtn}
        >
          <img src={AscendSvg} style={{ marginRight: 5 }} />
          <Typography className={classes.exchangeBtnText}>AscendEx</Typography>
        </Link>

        <Link
          href="https://www.gate.io/en/trade/DAFI_USDT"
          target="_blank"
          className={[classes.linkBtn]}
        >
          <img src={GateIOSVG} style={{ marginRight: 5, height: 30 }} />
          <Typography className={classes.exchangeBtnText}>Gate.io</Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default ExchangesCard;
