import { createTheme } from "@material-ui/core";
import { APP_CARD_BG_COLOR, APP_FONT_NAME } from "./utils/constants";

export const theme = createTheme({
  typography: {
    fontFamily: APP_FONT_NAME,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    color: "red",
  },

  palette: {
    cardBgColor: "#172347",
    snackbarColor:
      "linear-gradient(182.75deg,rgba(96, 83, 132, 0.7) -94.02%,rgba(15, 8, 36, 7) 153.25%)",

    themeColor1: "#0DF3C4",
    themeColor1RGBA: "13, 243, 196",
    themeGradient1:
      "linear-gradient(0deg, #0DF3C4, #0DF3C4), linear-gradient(0deg, #172347, #172347), linear-gradient(107.63deg, #302B63 -2.79%, #302B63 103.77%);",
    themeBoxShadow1:
      "inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12)",
    border: "2px solid rgba(13, 243, 196, 0.25)",

    textGradient1:
      "linear-gradient(29.15deg, #172347 1.81%, #025385 12.93%, #038298 24.06%, #07AAA8 30.73%, #0BD2B8 37.4%, #0DEAC1 42.96%, #0EF3C5 46.3%, #0EF3C5 68.55%, #0DEDC3 71.88%, #0CDDBC 76.33%, #09C2B1 81.89%, #069CA2 87.45%, #038298 90.79%, #015268 113.03%),linear-gradient(94.63deg, #65D7F5 -3.37%, #7D3CF0 89.21%),linear-gradient(94.63deg, #C881FF -3.37%, #FF4E80 89.21%),linear-gradient(104.25deg, #A35CED 0%, #FF377F 100%),linear-gradient(94.63deg, #8797FF -3.37%, #B55DFF 89.21%)",
    textColor: "rgba(255,255,255,.8)",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
});
