import { makeStyles } from "@material-ui/core";

import { TotalStakedStake } from "../../assets";
import { AMOUNT_FEE_RATIO, APP_TOKEN_NAME } from "../../utils/constants";

const InfoImg = ({ stakedAmount, rewards }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img src={TotalStakedStake} className={classes.img} />
        <small>You will get up to</small>
        <div className={[classes.dafiAmount, classes.unStakeColor].join(" ")}>
          {rewards > 0 && stakedAmount > 0
            ? `${Number(stakedAmount).toFixed(4)} + ${Number(
                rewards * AMOUNT_FEE_RATIO
              )?.toFixed(4)}`
            : rewards > 0
            ? `${Number(rewards * AMOUNT_FEE_RATIO)?.toFixed(4)}`
            : stakedAmount > 0
            ? `${Number(stakedAmount).toFixed(4)}`
            : stakedAmount}
        </div>
        <span className={classes.textSize_20}>{APP_TOKEN_NAME}</span>
      </div>
    </div>
  );
};

export default InfoImg;

const useStyles = makeStyles((theme) => ({
  container: { width: "100%" },
  dafiAmount: {
    fontSize: "28px",
    fontWeight: 700,
    margin: "5px 0px",
    textAlign: "center",
  },
  unStakeColor: {
    background: theme.palette.textGradient1,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },

  img: { marginBottom: "20px", height: 60 },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.cardBgColor,
    border: theme.palette.border,
    boxShadow:
      " inset 0px 23.0177px 33.051px -33px rgba(255, 255, 255, 0.5), inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12), inset 0px 57.8392px 59.0196px -28.3294px rgba(255, 255, 255, 0.09), inset 0px 2.36078px 10.6235px rgba(8, 5, 18, 0.2), inset 0px 0.590196px 23.6078px rgba(8, 5, 18, 0.2)",
    backdropFilter: "blur(25px)",
    paddingTop: "25px",
    paddingBottom: "30px",
    marginBottom: "20px",
    borderRadius: "25px",
    color: "white",
    "@media(max-width:550px)": {
      paddingTop: "16px",
      paddingBottom: "16px",
    },
    textSize_20: {
      fontSize: "20px",
    },
  },
}));
