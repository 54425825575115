import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  Zoom,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import {
  BinanceIcon,
  EthereumIcon,
  LockSvg,
  Matic2,
  PolygonPng,
} from "../../assets";
import { sixDigitsFormatter, trunc } from "../../utils/formatters";
import { Image, InfoOutlined } from "@material-ui/icons";
import { APP_TOKEN_NAME } from "../../utils/constants";
import { useSelector } from "react-redux";

const APYCard = ({ card, active, onClick }) => {
  console.log("SuperPoolCard => ", card);

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);
  const classes = useStyles({ card, active, currentNetwork });

  const [cardTitle, setCardTitle] = useState("");

  useEffect(() => {
    if (currentNetwork === 2) {
      setCardTitle("Ethereum");
    } else if (currentNetwork === 3) {
      setCardTitle("Polygon");
    } else if (currentNetwork === 5) {
      setCardTitle("Binance");
    }
  }, [currentNetwork]);

  console.log("cn => ", currentNetwork);

  return (
    <Box elevation={1} className={classes.card} onClick={onClick}>
      {/* {card.blur && <div className={classes.overlay}></div>} */}
      <Box className={classes.cardContent}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex={1}
        >
          <Box className={classes.logoContainer}>
            <img
              className={classes.image}
              // src={EthereumIcon}
              src={
                currentNetwork === 2
                  ? EthereumIcon
                  : currentNetwork === 5
                  ? BinanceIcon
                  : PolygonPng
              }
            />

            <Typography className={classes.title}>{cardTitle}</Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justify="space-between"
          alignItems="center"
          height="50%"
          className={classes.apyDataContainer}
        >
          <Box className={classes.sectionContainer}>
            <Typography className={classes.sectionValue}>
              {card.dafiLocked ? sixDigitsFormatter(card.dafiLocked) : "0"}
            </Typography>
            <Typography className={classes.sectionTitle}>
              {APP_TOKEN_NAME} Locked
            </Typography>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />

          <Box className={classes.sectionContainer}>
            <Typography className={classes.sectionValue}>
              {card.blur
                ? "--"
                : `${Number(card.APY) ? sixDigitsFormatter(card.APY) : "0"}%`}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography className={classes.sectionTitle}>APY</Typography>
              <Tooltip
                title={`Your d${APP_TOKEN_NAME} rewards can self-multiply at peak demand and become the 'Potential APY' displayed.`}
                arrow
                TransitionComponent={Zoom}
              >
                <IconButton
                  aria-label="delete"
                  style={{ padding: 0, marginLeft: 5 }}
                >
                  <InfoOutlined style={{ color: "#fff", fontSize: 16 }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />

          <Box className={classes.sectionContainer}>
            <Typography className={classes.sectionValue}>
              {card.blur
                ? "--"
                : `${
                    card.potentialAPY
                      ? sixDigitsFormatter(card.potentialAPY)
                      : "0"
                  }%`}
            </Typography>
            <Typography className={classes.sectionTitle}>
              Potential APY
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default APYCard;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: 20,

    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // flexDirection: "column",
    boxShadow: "none",

    [theme.breakpoints.up(600)]: {
      height: 200,
    },

    // width: "auto",

    // [theme.breakpoints.up("sm")]: {
    //   maxWidth: 350,
    // },

    // margin: "auto",
    // justifyContent: "center",
    marginRight: 16,
    marginBottom: 16,
    position: "relative",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },

  text: {
    color: "rgba(255,255,255,.8)",
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
  },

  value: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "20px",
    color: "#fff",
    marginBottom: 20,
  },

  cardContent: {
    display: "flex",

    padding: 24,

    // justifyContent: "space-between",
    flex: 1,
    alignItems: "center",

    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
    },

    [theme.breakpoints.up(400)]: {
      padding: 16,
    },
  },

  image: {
    height: 60,
    marginBottom: 24,
  },

  title: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "14px",
    color: "#fff",
    [theme.breakpoints.down(400)]: {
      fontSize: 22,
    },
  },

  sectionTitle: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "14px",
    color: "#fff",
    textAlign: "center",
    [theme.breakpoints.down(400)]: {
      fontSize: 14,
    },
  },

  sectionValue: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: "14px",
    color: ({ currentNetwork }) =>
      currentNetwork === 2
        ? "#BBD0FF"
        : currentNetwork === 5
        ? "rgb(243, 186, 47,.9)"
        : "#a37ce6",
    marginBottom: 30,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(600)]: {
      marginBottom: 10,
    },
  },

  divider: {
    width: 2,
    background: "rgba(255, 255, 255, 0.1)",
  },

  sectionContainer: {
    padding: "0 50px",

    [theme.breakpoints.down(1100)]: {
      padding: "0 40px",
    },

    [theme.breakpoints.down(700)]: {
      padding: "0 25px",
    },

    [theme.breakpoints.down(500)]: {
      padding: "0 16px",
    },

    [theme.breakpoints.down(600)]: {
      marginBottom: 40,
    },

    [theme.breakpoints.down(440)]: {
      padding: "0 11px",
    },
    [theme.breakpoints.down(410)]: {
      padding: "0 10px",
    },
    [theme.breakpoints.down(400)]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },

  overlay: {
    position: "absolute",

    backdropFilter: "blur(6px)",
    height: "100%",
    width: "100%",
  },

  logoContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.down(600)]: {
      marginBottom: 40,
    },
  },

  apyDataContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50%",

    [theme.breakpoints.down(440)]: {
      minWidth: "92%",
    },
    [theme.breakpoints.down(400)]: {
      flexDirection: "column",
      height: "100%",
    },
  },
}));
