import React from "react";
import { useSelector } from "react-redux";

import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import {
  Modal,
  Container,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import { useWeb3React } from "@web3-react/core";
import { getDifferenceInDaysFromNow } from "../../utils/formatters";
import BlockBtn from "../stake_components/BlockBtn";

const CustomModal = ({
  open,
  onClose,
  text,
  modalFunc,

  loadText,

  stakingPeriod,
  stakingPeriodValue,
  minStakingPeriod,
}) => {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.ui);

  console.log("stakingPeriod => ", stakingPeriod);

  const web3Context = useWeb3React();

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        disableBackdropClick
        className={classes.dialog}
        PaperProps={{
          style: { background: "transparent", top: 20 },
        }}
        classes={{
          paper: classes.dialogPosition,
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container className={classes.container}>
          <div className={classes.box}>
            <IconButton onClick={onClose} className={classes.closeIcon}>
              <Close style={{ color: "#fff" }} />
            </IconButton>
          </div>
          <div className={classes.centerAlign}>
            {!stakingPeriod && (
              <div className={classes.infoText}>
                {web3Context?.chainId === 1
                  ? `You will be able to withdraw after ${getDifferenceInDaysFromNow(
                      stakingPeriodValue,
                      minStakingPeriod
                    )}`
                  : `You will be able to withdraw after ${getDifferenceInDaysFromNow(
                      stakingPeriodValue,
                      minStakingPeriod
                    )}`}
              </div>
            )}

            {loading && (
              <>
                <CircularProgress className={classes.spinner} size={50} />
                <div className={classes.headerText}>
                  {`Transaction in process`}
                </div>
              </>
            )}
            {loading ? (
              <div className={classes.infoText}>{loadText}</div>
            ) : !stakingPeriod ? (
              ""
            ) : (
              <div className={classes.infoText}>{text}</div>
            )}
            {!loading && (
              <BlockBtn
                label={"Proceed"}
                handleClick={() => modalFunc()}
                disabled={!stakingPeriod}
              />
            )}
          </div>
        </Container>
      </Modal>
    </>
  );
};

CustomModal.defaultProps = {
  loading: false,
  setLoading: () => {},
  selected: "",
};
export default CustomModal;

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "10px",
  },
  closeIcon: {
    padding: "0px",
  },
  container: {
    background: theme.palette.cardBgColor,
    color: "#fff",
    paddingTop: "20px",
    paddingBottom: "20px",
    borderRadius: "10px",
    width: "332px",
    "@media(max-width:550px)": {
      // width: "fit-content",
      //
    },

    // marginTop: "10000px",
  },
  dialog: {
    background: "#000000B3",
    // display: "none",  position: "absolute",
    background: "rgba(15, 8, 36, 0.55)",
    border: "2px solid rgba(13, 243, 196, 0.25)",
    boxShadow:
      "inset 0px 23.0177px 33.051px -33px rgba(255, 255, 255, 0.5), inset 0px 4.13137px 6.49216px -2.36078px rgba(255, 255, 255, 0.25), inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12), inset 0px 57.8392px 59.0196px -28.3294px rgba(255, 255, 255, 0.12), inset 0px 0.590196px 23.6078px rgba(8, 5, 18, 0.2)",
    backdropFilter: "blur(5px)",
    minWidth: 200,
  },
  dialogPosition: {
    position: "absolute",
    //left: "40%",
    // left: "50%",
    // top: "50%",
    // transform: "translate(-50%,-50%)",
    transform: "translateY(30vh)",
    "@media(max-width:550px)": {
      transform: "translateY(50%)",
    },
  },
  infoImg: {
    marginBottom: "24px",
  },
  infoText: {
    textAlign: "center",
    marginBottom: "26px",
    width: "80%",
  },
  headerText: {
    textAlign: "center",
    marginBottom: "5px",
    width: "90%",
  },
  centerAlign: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  spinner: {
    color: theme.palette.themeColor1,
    marginBottom: "34px",
  },
}));
