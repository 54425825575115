import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import CustomCard from "./CustomCard";
import BottleVertical from "../Misc/BottleVertical";
import { BinanceIcon, EthereumIcon, MaticIcon } from "../../assets";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  card: {
    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",
    border: "1px solid rgba(230, 231, 233, 0.1)",
    borderRadius: 25,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "none",

    marginTop: 20,
    height: 430,
  },

  text: {
    color: "rgba(255,255,255,.8)",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "500",
  },

  value: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "20px",
    color: "#fff",
    marginBottom: 20,
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },

  image: {
    alignItems: "center",
    width: 80,
    marginBottom: 24,
    height: 80,
  },
  timeFrameBtn: {
    background: "rgba(15, 8, 36, 0.55)",
    border: "1px solid rgba(230, 231, 233, 0.1)",
    boxShadow: "inset 0px 23.0177px 33.051px -33px rgba(255, 255, 255, 0.5)",
    borderRadius: "10px",
    color: "#fff",
    marginRight: 12,
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "600",
    height: 32,
  },
});

const PoolCard = ({ data }) => {
  const classes = useStyles();

  const OverallTotalStakes = useSelector(
    (state) => state.web3.data.OverallTotalStakes
  );

  const [polygonPool, setPolygonPool] = useState("0");
  const [bscPool, setBscPool] = useState("0");
  const [ethV2Pool, setEthV2Pool] = useState("0");

  useEffect(() => {
    if (OverallTotalStakes) {
      let totalStaked =
        Number(OverallTotalStakes?.maticV2) +
        Number(OverallTotalStakes?.bscV2) +
        Number(OverallTotalStakes?.ethereumV2);

      console.log("totalStaked => => ", {
        maticStakes: (OverallTotalStakes?.maticV2 * 100) / totalStaked,
        totalStaked,
        ethStakes: Math.round((OverallTotalStakes?.bscV2 * 100) / totalStaked),
        maticOverAll: OverallTotalStakes?.maticV2,
        ethOverAll: OverallTotalStakes?.bscV2,
        OverallTotalStakes,
      });

      if (OverallTotalStakes?.maticV2) {
        setPolygonPool((OverallTotalStakes?.maticV2 * 100) / totalStaked);
      }

      if (OverallTotalStakes?.bscV2)
        setBscPool((OverallTotalStakes?.bscV2 * 100) / totalStaked);

      if (OverallTotalStakes?.ethereumV2)
        setEthV2Pool((OverallTotalStakes?.ethereumV2 * 100) / totalStaked);
    }
  }, [OverallTotalStakes]);

  return (
    <CustomCard>
      <CardContent className={classes.cardContent}>
        <Box style={{ marginBottom: 16 }}>
          <Typography className={classes.text}>Pool Dominance</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <BottleVertical
            gradient="linear-gradient(356.76deg, #2980b9 2.65%, #6dd5fa 134.83%)"
            height={`${ethV2Pool}`}
            labelColor="#56B8E4"
            icon={EthereumIcon}
            label="Ethereum"
          />

          <BottleVertical
            gradient="linear-gradient(169.06deg, #F6610F 1.93%, #F3A929 97%)"
            height={`${bscPool}`}
            labelColor="#F66711"
            icon={BinanceIcon}
            label="Binance"
          />
          {/* <BottleVertical
            gradient="linear-gradient(169.06deg, #F6610F 1.93%, #F3A929 97%)"
            height={`${bscPool}`}
            labelColor="#F66711"
            icon={BinanceIcon}
            label="Binance"
          /> */}
          <BottleVertical
            gradient="linear-gradient(169.06deg, #6210E1 1.93%, #8C2BE2 97%)"
            height={`${polygonPool}`}
            marginRight={false}
            labelColor="#a37ce6"
            icon={MaticIcon}
            label="Polygon"
          />
        </Box>
      </CardContent>
    </CustomCard>
  );
};

export default PoolCard;
