import {
  Box,
  makeStyles,
  Typography,
  Link,
  ClickAwayListener,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowDownSvg,
  BinanceIcon,
  DiscordIconSvg,
  DocsIcon,
  EthereumIcon,
  MaticIcon,
  TelegramIcon,
} from "../../assets";
import { sendTransaction } from "../../contract/functions/functions";
import { setCurrentNetwork } from "../../redux";
import { APP_DISCORD_URL, APP_TELEGRAM_URL } from "../../utils/constants";
import Text from "./Text";

const NetworkDropdown = ({ borderRadius, value, setValue }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const [currentOption, setCurrentOption] = useState(0);
  const classes = useStyles({ showDropdown, borderRadius });

  const dispatch = useDispatch();
  const isTrustWallet = useSelector((state) => state.web3.isTrustWallet);

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);
  const networkSet = useSelector((state) => state.web3.networkSet);

  const [activeChain, setActiveChain] = useState(0);

  console.log("CustomDropdown = ", value);

  useEffect(() => {
    if (currentNetwork === 2) {
      setActiveChain(0);
    } else if (currentNetwork === 3) {
      setActiveChain(1);
    } else if (currentNetwork === 5) {
      setActiveChain(2);
    }
  }, [currentNetwork]);

  const options = [
    {
      title: "Ethereum",

      icon: EthereumIcon,
      chainId: "0x1",
      network: 2,
    },
    {
      title: "Polygon",
      icon: MaticIcon,
      chainId: "0x89",
      network: 3,
    },
    {
      title: "Binance",
      icon: BinanceIcon,
      chainId: "0x38",
      network: 5,
    },
  ];

  useEffect(() => {
    setShowDropdown(false);
  }, [currentOption]);

  const onChainChanged = (network, index) => {
    console.log("Setting network => ", network, index);

    dispatch({
      type: "RESET_DATA",
    });
    dispatch(setCurrentNetwork(network));

    setActiveChain(index);
    // dispatch({
    //   type: "SET_NETWORK",
    //   payload: true,
    // });
  };

  const onNetworkChange = async (chainId, network, index) => {
    console.log("onNetworkChange => ", {
      currentNetwork,
      network,
      isTrustWallet,
      networkSet,
    });

    dispatch({
      type: "SET_NETWORK",
      payload: false,
    });

    if (!isTrustWallet) {
      console.log("Change network==>");
      if (currentNetwork === 1 && network === 2) {
        // dispatch({
        //   type: "RESET_DATA",
        // });
        // dispatch(setCurrentNetwork(network));
      } else {
        sendTransaction(chainId, () => onChainChanged(network, index));
      }
    } else {
      dispatch({
        type: "RESET_DATA",
      });
      dispatch({
        type: "SET_NETWORK",
        payload: true,
      });
      if (currentNetwork === 1 && network === 2) {
        // dispatch(setCurrentNetwork(network));
      } else if (currentNetwork === 2 && network === 1) {
        // dispatch(setCurrentNetwork(network));
      } else {
        dispatch(setCurrentNetwork(network));
      }
    }
  };
  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <Box className={classes.container}>
        <Box
          className={classes.itemShowContainer}
          onClick={() => setShowDropdown((val) => !val)}
        >
          <img
            src={options[activeChain].icon}
            className={classes.networkImage}
          />

          <Text
            text={options[activeChain].title}
            fontSize={16}
            lineHeight={14}
            fontWeight={400}
            color="#fff"
          />

          <img src={ArrowDownSvg} style={{ marginLeft: 10 }} />
        </Box>

        {showDropdown && (
          <Box className={classes.itemsContainer}>
            {options.map((option, index) => (
              <Box
                className={classes.itemContainer}
                onClick={() => {
                  if (activeChain !== index)
                    onNetworkChange(option.chainId, option.network, index);
                }}
              >
                <Box display="flex" alignItems="center" marginBottom="5px">
                  <Box className={classes.iconContainer}>
                    <img src={option.icon} className={classes.icon} />
                  </Box>

                  <Typography className={classes.title}>
                    {option.title}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default NetworkDropdown;

const useStyles = makeStyles((theme) => ({
  container: {
    background: "red",
    // height: "100%",
    width: "150px",
    height: 50,
    // marginBottom: "3rem",

    background: theme.palette.cardBgColor,

    border: "2px solid rgba(13, 243, 196, 0.25)",
    borderRadius: "10px",
    // border: "1px solid rgba(255, 255, 255, 0.08)",
    borderRadius: 10,
    cursor: "pointer",

    position: "relative",
    marginRight: 15,

    [theme.breakpoints.down("600")]: {
      display: "none",
    },
  },

  currentItem: {
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    fontWeight: 400,
    color: "#fff",
  },

  item: {
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    fontWeight: 400,
    color: "#fff",
    padding: "1rem",

    "&:hover": {
      textDecoration: "none",
    },
  },

  itemContainer: {
    padding: "10px 0px",
  },

  itemsContainer: {
    position: "absolute",
    top: 48,
    width: "150px",
    right: 0,
    borderRadius: 10,

    background: theme.palette.cardBgColor,

    border: "1px solid rgba(255, 255, 255, 0.28)",

    zIndex: 10000,

    // borderRadius: "25px",

    boxShadow:
      "inset 0px 2.36078px 10.6235px rgba(8, 5, 18, 0.2), inset 0px 0.590196px 23.6078px rgba(8, 5, 18, 0.2)",
    backdropFilter: "blur(40px)",

    padding: "10px 15px",
  },

  itemShowContainer: {
    // padding: "0 2rem",

    height: "100%",
    width: "100%",
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    padding: "0 15px",
  },
  infoIcon: {
    color: "#fff",
  },

  iconContainer: {
    height: 20,
    width: 20,
    // backgroundColor: "#797290",
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    borderRadius: 10,
  },
  icon: {
    height: 20,
    width: 20,
  },
  title: {
    fontSize: "16px",
    lineHeight: "14px",
    fontWeight: 400,
    color: "#fff",
  },

  description: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
    color: "#fff",
  },
  networkImage: {
    height: 20,
    width: 20,
    marginRight: 8,
  },
}));
