import { makeStyles, InputAdornment, TextField } from "@material-ui/core";

import { PolygenLogo } from "../../assets";

const AmountInput = ({
  userBalance,
  userInput,
  inputErrText,
  showInputErr,
  disabled,
  onChangeInput,
  onMaxClick,
  maxDisabled,
  type,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.amountBoxLabel}>
        <div>Enter Amount</div>
        {type === "stake" && (
          <div>
            Your Balance:{"  "}
            <span className={classes.balanceText}>
              {userBalance ? Number(userBalance).toFixed(2) : 0}
            </span>
          </div>
        )}
      </div>
      <TextField
        id="outlined-basic"
        // variant="outlined"
        className={[
          classes.textField,
          showInputErr ? classes.errBorder : null,
        ].join(" ")}
        value={userInput}
        disabled={disabled}
        placeholder={disabled ? "" : "000"}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <img src={PolygenLogo} className={classes.logo} />

              <div className={classes.verticalLine}></div>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <button
                className={classes.maxBtn}
                onClick={onMaxClick}
                disabled={maxDisabled}
              >
                max
              </button>
            </InputAdornment>
          ),
          className: classes.input,
        }}
        onChange={(e) => onChangeInput(e.target.value)}
      />
      <span className={classes.errText}>
        {showInputErr && <div>{inputErrText}</div>}
      </span>
    </>
  );
};

export default AmountInput;

const useStyles = makeStyles((theme) => ({
  amountBoxLabel: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "11px",
    color: "#fff",
    fontSize: "13px",
    fontWeight: 400,
    "@media(max-width:550px)": {
      fontSize: "12px",
    },
  },
  balanceText: {
    fontWeight: 600,
  },
  errBorder: {
    border: "1px solid #AE4141",
  },
  errText: {
    color: "#fff",
    fontSize: "13px",
    width: "100%",
    textAlign: "left",
    // display: "none",
    fontWeight: 400,
    marginBottom: "6px",
    "@media(max-width:550px)": {
      fontSize: "15px",
    },
  },
  input: {
    color: "white",
    fontSize: "20px",
    fontWeight: 600,
  },
  logo: {
    margin: "0px 25px",
    marginLeft: "10px",

    "@media(max-width:550px)": {
      margin: "0px 15px",
      marginLeft: "5px",
    },

    width: 25,
  },
  maxBtn: {
    background: theme.palette.cardBgColor,
    color: "#ffffff !important",
    fontFamily: "Poppins",
    width: "93px",
    borderRadius: "10px",
    height: "33px",
    border: theme.palette.border,
    fontWeight: "400",
    fontSize: "12px",
    "@media(max-width:550px)": {
      width: "55px",
    },
    cursor: "pointer",
    "&:disabled": {
      color: "#ffffff1A",
      cursor: "default",
    },
  },
  textField: {
    display: "flex",
    justifyContent: "center",
    width: "100%",

    // marginTop: "49px",
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    color: "white",
    borderRadius: "10px",
    [`& fieldset`]: {
      borderRadius: "10px",
    },
    marginBottom: "10px",
    ["& focus"]: {
      borderColor: "yellow",
      border: "1px solid green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#red",
      borderWidth: "2px",
    },
    "@media(max-width:550px)": {
      height: "52px",
    },
    padding: "10px",
  },

  verticalLine: {
    borderLeft: "1px solid #ffffff1A",
    height: "52px",
    marginRight: "17px",
  },
}));
