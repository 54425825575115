import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { store } from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";
import UnderMaintenance from "./containers/UnderMaintenance";

let underMaintenance = false;

if (!underMaintenance) {
  ReactDOM.render(
    <React.StrictMode>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  let password = prompt("Enter password to continue");
  if (password === "mimir_dev") {
    ReactDOM.render(
      <React.StrictMode>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
